import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    Output,
} from '@angular/core';
import { BazisConfigurationService } from '@bazis/configuration.service';
import { TooltipSettings } from '@bazis/form/models/form-element.types';

@Component({
    selector: 'bazis-control-field-start',
    templateUrl: './control-field-start.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BazisControlFieldStartComponent {
    @HostBinding('class') get hostClass() {
        return {
            'bazis-control__field-group__start':
                this.icon || (this.tooltipSettings?.targetSide === 'left' && this.tooltipText),
            'bh-contents': !this.icon && this.tooltipSettings?.targetSide !== 'left',
        };
    }

    @Input() icon: string = null;

    @Input() isClickable: boolean = null;

    @Input() readonly: boolean = null;

    @Input() tooltipText: string = null;

    // настройки отображения и работы тултипа
    @Input() tooltipSettings: TooltipSettings = null;

    // событие по клику на иконке
    @Output() actionClick = new EventEmitter();

    icons;

    // светлая или темная тема подсказки по иконке
    interrogationType: 'dark' | 'light' = this.configurationService.controlInterrogationType;

    constructor(private configurationService: BazisConfigurationService) {
        this.icons = this.configurationService.controlIcons;
    }

    onClick(e) {
        this.actionClick.emit(e);
    }
}
