import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BazisInputOptionsComponent } from '@bazis/form/components/base/options/input-options.component';
import { v4 as uuidv4 } from 'uuid';

@Component({
    selector: 'bazis-select',
    templateUrl: './select.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BazisSelectComponent extends BazisInputOptionsComponent {
    // отображение (только визуально, не меняя логики) в виде списка чекбоксов
    @Input() byCheckbox: boolean = true;

    // дефолтное значение, необходимо для списков сортировки и подобным,
    // когда что-то обязательно должно быть выбрано
    @Input() defaultValueId: string;

    // внешний вид, secondary - серый
    @Input() type: 'default' | 'secondary' = 'default';

    // метка поля, задействованная в теле option
    @Input() optionLabel: string;

    // возможность unselect пункта
    @Input() togglable = true;

    @Input() iconUp: string = this.icons.caretUp;

    @Input() iconDown: string = this.icons.caretDown;

    @Input() emptyListKey: string = '';

    public readonly attrId = `${uuidv4()}`;

    showList = false;

    toggleList() {
        this.showList = !this.showList;
        this.markAsTouched();
    }

    hideList() {
        this.showList = false;
    }

    toggleValue(id) {
        const selected = this.values._;

        if (!this.togglable && !this.multiple && selected.indexOf(id) > -1) {
            return;
        }

        if (
            !this.defaultValueId ||
            this.defaultValueId !== id ||
            (this.defaultValueId == id && selected[0] !== id)
        ) {
            super.toggleValue(id);
        }

        if (
            this.defaultValueId &&
            this.defaultValueId !== id &&
            this.values._.length === 1 &&
            selected[0] === id
        ) {
            super.toggleValue(this.defaultValueId);
        }
        if (!this.multiple) {
            this.hideList();
        }
    }
}
