<ng-container *ngIf="icon">
    <bazis-button
        *ngIf="isClickable && !readonly"
        fill="clear"
        (click)="onClick($event)"
    >
        <bazis-icon
            slot="icon-only"
            [icon]="icon"
        ></bazis-icon>
    </bazis-button>
    <bazis-icon
        *ngIf="!isClickable"
        [icon]="icon"
    ></bazis-icon>
</ng-container>
<ng-container *ngIf="tooltipSettings?.targetSide === 'left' && tooltipText">
    <bazis-interrogation
        iconSize="xs"
        [tooltipText]="tooltipText"
        [tooltipOffset]="[-8, 4]"
        [tooltipEventType]="tooltipSettings?.eventType || 'hover'"
        [tooltipPlacement]="tooltipSettings?.placement || 'bottom-start'"
        [icon]="icons.interrogation"
        [type]="interrogationType"
    ></bazis-interrogation>
</ng-container>
