<bazis-icon
    *ngIf="item.$snapshot.icon"
    [icon]="item.$snapshot.icon"
    [size]="iconSize"
></bazis-icon>
<span
    *transloco="let t"
    [innerHtml]="item.$snapshot.name || t(item.$snapshot.nameKey, item.$snapshot.nameParams)"
>
    <!-- {{ item.$snapshot.name || t(item.$snapshot.nameKey, item.$snapshot.nameParams) }} -->
</span>
