<ng-container *transloco="let t">
    <ng-container
        *ngIf="{
            status: ngControl.control.statusChanges | async,
            value: ngControl.control.valueChanges | async
        } as data"
    >
        <ng-container>
            <p *ngFor="let error of errors">
                <span
                    *ngIf="validationErrorMessages[error]"
                    [innerHTML]="t(validationErrorMessages[error], ngControl.control.errors[error])"
                ></span>
                <span *ngIf="!validationErrorMessages[error]">
                    {{ t('form.error.' + error, ngControl.control.errors[error]) }}
                </span>
            </p>
        </ng-container>
    </ng-container>
</ng-container>
