import {
    Component,
    ChangeDetectionStrategy,
    Input,
    HostBinding,
    EventEmitter,
    Output,
} from '@angular/core';
import { BazisConfigurationService } from '@bazis/configuration.service';
import { TooltipSettings } from '@bazis/form/models/form-element.types';

@Component({
    selector: 'bazis-control-select-end',
    templateUrl: './control-select-end.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BazisControlSelectEndComponent {
    @HostBinding('class.bazis-control__field-group__end') get hostClass() {
        return true;
    }

    @Input() tooltipText: string = null;

    // настройки отображения и работы тултипа
    @Input() tooltipSettings: TooltipSettings = null;

    @Input() showList: boolean = false;

    @Input() iconUp: string = this.configurationService.controlIcons.caretUp;

    @Input() iconDown: string = this.configurationService.controlIcons.caretDown;

    @Output() toggled = new EventEmitter();

    icons;

    // светлая или темная тема подсказки по иконке
    interrogationType: 'dark' | 'light' = this.configurationService.controlInterrogationType;

    constructor(private configurationService: BazisConfigurationService) {
        this.icons = this.configurationService.controlIcons;
    }

    toggleList() {
        this.toggled.emit();
    }
}
