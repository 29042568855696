import { Injectable } from '@angular/core';
import { BazisChatService } from '@bazis/chat/shared/services/chat.service';
import { BazisEntityService } from '@bazis/shared/services/entity.service';
import { BazisSrvService } from '@bazis/shared/services/srv.service';
import { BazisAuthService } from '@bazis/shared/services/auth.service';
import { BazisWebSocketService } from '@bazis/shared/services/web-socket.service';
import { ROLE } from '@app/configuration.service';
import { filter, map } from 'rxjs/operators';
import { switchMap } from 'rxjs';
import { BazisToastService } from '@bazis/shared/services/toast.service';

export type ChatType = 'bank/agent' | 'bank/client' | 'agent/client' | 'agent_questions';

@Injectable({
    providedIn: 'root',
})
export class ChatService extends BazisChatService {
    generalSettings = {
        participantsSettings: [
            {
                id: 'bank',
                groupTitleKey: 'chat.participants.bank',
                sourceField: 'autogen_bank_users_selectors',
                roles: [ROLE.partner, ROLE.manager, ROLE.clientManager],
            },
            {
                id: 'client',
                groupTitleKey: 'chat.participants.client',
                sourceField: 'autogen_client_users_selectors',
                roles: [ROLE.client],
            },
            {
                id: 'agent',
                groupTitleKey: 'chat.participants.agent',
                sourceField: 'autogen_agent_users_selectors',
                roles: [ROLE.agent],
            },
        ],
        addParticipantSettings: {
            filterByUserOrganization: true,
            filterParams: null,
            sort: null,
            entityType: null,
        },
        chatTitle$: (chat) => {
            return this.authService.role$.pipe(
                filter((v) => v && v !== ROLE.anonymous),
                switchMap((role) => {
                    return this.entityService.getEntity$(
                        role !== ROLE.agent && chat.$snapshot.context_model === 'negotiation.lot'
                            ? 'negotiation.bid'
                            : chat.$snapshot.context_model,
                        chat.$snapshot.context_id,
                    );
                }),
                map((bid) => {
                    if (bid.$snapshot.customer_name) {
                        return {
                            action: null,
                            name: bid.$snapshot.customer_name,
                        };
                    }
                    return {
                        action: null,
                        nameKey: 'payment.title.individual',
                        nameParams: {
                            name:
                                bid.$snapshot.user_info.author_last_name +
                                (bid.$snapshot.user_info.author_first_name
                                    ? ' ' + bid.$snapshot.user_info.author_first_name[0] + '.'
                                    : ''),
                        },
                    };
                }),
            );
        },
    };

    constructor(
        protected entityService: BazisEntityService,
        protected srvService: BazisSrvService,
        protected authService: BazisAuthService,
        protected socketService: BazisWebSocketService,
        protected toastService: BazisToastService,
    ) {
        super(entityService, srvService, authService, socketService, toastService);
    }

    getChatContextUrl(contextModel, contextId, role = null) {
        const contextModelsToUrls = {
            'negotiation.bid': (contextId) =>
                role === ROLE.client || role === ROLE.agent
                    ? '/payments/bids/' + contextId
                    : '/payments/bids/partner/' + contextId,
            'negotiation.lot': (contextId) =>
                role === ROLE.agent
                    ? '/payments/lots/' + contextId
                    : role === ROLE.client
                    ? '/payments/bids/' + contextId
                    : '/payments/bids/partner/' + contextId,
        };

        return contextModelsToUrls[contextModel]
            ? contextModelsToUrls[contextModel](contextId)
            : null;
    }

    openOrganizationData(organizationId) {
        return false;
    }
}
