import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    Self,
    SimpleChanges,
    ChangeDetectorRef,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { catchError, map } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, mergeMap, Observable, of, shareReplay } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { EntData, SearchSettings, SimpleData } from '@bazis/shared/models/srv.types';
import { BazisEntityService } from '@bazis/shared/services/entity.service';
import { TemplateObservable } from '@bazis/shared/classes/template-observable';
import { v4 as uuidv4 } from 'uuid';
import { TranslocoService } from '@jsverse/transloco';
import {
    BazisConfigurationService,
    DEFAULT_LIST_LIMIT,
    SHARE_REPLAY_SETTINGS,
} from '@bazis/configuration.service';
import { BazisControlValueAccessor } from '@bazis/form/components/control-value-accessor.component';

@UntilDestroy()
@Component({
    selector: 'bazis-input-options',
    templateUrl: './input-options.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BazisInputOptionsComponent extends BazisControlValueAccessor implements OnChanges {
    @Input() multiple: boolean = true;

    @Input() forceCheckbox: boolean = null;

    @Input() entityType: string = null;

    @Input() searchSettings: SearchSettings = null;

    @Input() options: EntData[] | SimpleData[] = null;

    @Input() captionEmpty: string = null;

    @Input() limit: number = DEFAULT_LIST_LIMIT;

    // если надо показать только конкретные эл-ты из списка. Передаем массив id этих эл-тов
    @Input() includeOnly: string[] = null;

    // если надо исключить какие-то эл-ты из списка. Передаем массив id этих эл-тов
    @Input() excludeItems: string[] = null;

    // если надо вывести список в одну строчку
    @Input() isOneline: boolean = false;

    // если необходимо задать расположение чекбокса (radio) относительно метки
    @Input() direction: 'right' | 'left' = 'left';

    @Input() selectAllKey: string = null;

    @Input() resetAllKey: string = null;

    @Input() resetSelectionOnUpdateOptions: 'reset' | 'keepExistedInNewList' | 'keepSelection' =
        'keepSelection';

    // отображение метки опции выделенным стилем
    @Input() hasLabelMajor: boolean = false;

    // is empty value = null, can be false for enums
    @Input() isNullable: boolean = true;

    // При множественном выборе, отображение частичного выбора
    @Input() isSelectedPartial: boolean = false;

    values: TemplateObservable<(string | number | boolean)[]> = new TemplateObservable([]);

    needUpdateOptionsList$ = new BehaviorSubject(false);

    disabled = new TemplateObservable(false);

    allValues = [];

    options$: Observable<EntData[]> = this.needUpdateOptionsList$.pipe(
        mergeMap(() => {
            if (this.options) return of(this.entityService.toEntitiesList(this.options));
            if (this.entityType || this.searchSettings?.entityType)
                return this.searchSettings
                    ? this.entityService
                          .getEntityList$(this.searchSettings.entityType || this.entityType, {
                              suffix: this.searchSettings.suffix || '',
                              params: this.searchSettings.params || {},
                              limit: this.limit,
                          })
                          .pipe(catchError((e) => of({ list: [] })))
                    : this.entityService
                          .getAllEntitiesList$(this.entityType)
                          .pipe(catchError((e) => of({ list: [] })));
            return of(null);
        }),
        map((entityList) => {
            if (this.includeOnly && entityList) {
                entityList.list = entityList.list.filter(
                    (entity) => this.includeOnly.indexOf(entity.id) > -1,
                );
            }
            if (this.excludeItems && entityList) {
                entityList.list = entityList.list.filter(
                    (entity) => this.excludeItems.indexOf(entity.id) === -1,
                );
            }
            const list = entityList ? entityList.list : null;

            this.allValues = list ? list.map((v) => v.id) : [];

            if (this.selectAllKey && list) {
                list.unshift({
                    id: '#all',
                    type: this.entityType,
                    $snapshot: {
                        nameKey: 'action.selectAllCount',
                        nameParams: { count: list.length },
                    },
                });
            }
            if (this.captionEmpty && list && !list.find((item) => item.id === null)) {
                list.unshift({
                    id: null,
                    type: this.entityType,
                    $snapshot: {
                        nameKey: this.captionEmpty,
                    },
                });
            }
            if (this.resetSelectionOnUpdateOptions === 'reset') {
                this.toggleValue('');
            }
            if (this.resetSelectionOnUpdateOptions === 'keepExistedInNewList') {
                this.setNewValues(
                    this.values._.filter((value) => list.findIndex((v) => v.id === value) > -1),
                );
            }
            return list;
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    selectedNames$ = combineLatest([this.options$, this.values.$]).pipe(
        map(([options]) => {
            if (!options || !options.length) return '';

            return options
                .filter((v) => this.values._.indexOf(v.id) > -1)
                .map((v) => v.$snapshot.name)
                .join(', ');
        }),
    );

    selectedValues$ = combineLatest([this.options$, this.values.$]).pipe(
        map(([options]) => {
            if (!options || !options.length) return [];

            const selected = !this.values._.length
                ? this.captionEmpty
                    ? [null]
                    : this.values._
                : this.values._;

            return options.filter((v) => selected.indexOf(v.id) > -1);
        }),
    );

    public readonly attrName = `${uuidv4()}`;

    constructor(
        @Self() public ngControl: NgControl,
        protected cdr: ChangeDetectorRef,
        public configurationService: BazisConfigurationService,
        protected entityService: BazisEntityService,
        protected translateService: TranslocoService,
    ) {
        super(ngControl, cdr, configurationService);
        ngControl.valueAccessor = this;
    }

    extendOnInit() {
        if (this.searchSettings?.limit) this.limit = this.searchSettings.limit;

        // writeValue в инит отсутствует
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            (changes.options && changes.options.previousValue !== changes.options.currentValue) ||
            (changes.includeOnly &&
                changes.includeOnly.previousValue !== changes.includeOnly.currentValue) ||
            (changes.searchSettings &&
                changes.searchSettings.previousValue !== changes.searchSettings.currentValue) ||
            (changes.entityType &&
                changes.entityType.previousValue !== changes.entityType.currentValue)
        ) {
            this.needUpdateOptionsList$.next(true);
        }
    }

    public writeValue(value): void {
        this.values.set(this.multiple && Array.isArray(value) ? [...value] : [value]);
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled.set(isDisabled);
    }

    toggleValue(id) {
        if (this.disabled._) return;
        let value = this.values._;
        if (!id && id !== false) {
            value = [];
        } else if (id === '#all') {
            value = value.indexOf(id) === -1 ? [...this.allValues] : [];
        } else {
            if (value.indexOf(id) > -1) {
                value = value.filter((v) => v !== id);
            } else {
                if (this.multiple) {
                    value.push(id);
                } else {
                    value = [id];
                }
            }
        }
        if (this.captionEmpty) {
            value = value.filter((v) => !!v);
            if (value.length === 0) {
                value = [null];
            }
        }

        this.setNewValues(value);

        this.markAsTouched();
    }

    setNewValues(value) {
        if (value.filter((v) => v !== '#all').length === this.allValues.length) {
            value.push('#all');
        } else {
            value = value.filter((v) => v !== '#all');
        }

        this.values.set(value);

        const valueToEmit = value.filter((v) => !!v && v !== '#all');

        const emptyValue = this.forceCheckbox ? false : this.isNullable ? null : '';
        if (!this.multiple) {
            let emitValue = valueToEmit[0];
            if (!emitValue && emitValue !== false) {
                emitValue = emptyValue;
            }
            this.onChange(emitValue);
        } else {
            this.onChange([...valueToEmit]);
        }
    }

    preventDefault(e) {
        e.preventDefault();
        e.stopImmediatePropagation();
    }
}
