<ng-container *transloco="let t">
    <div
        class="bazis-item"
        [ngClass]="{
            'bazis-item--selected': values.indexOf(option.id) > -1,
            'bazis-checkbox': byCheckbox && multiple
        }"
        (click)="toggleValue($event)"
    >
        <span [class.bazis-checkbox__label]="byCheckbox && multiple">
            <bazis-icon
                *ngIf="option.$snapshot.icon"
                [icon]="option.$snapshot.icon"
                [style.margin-right]="'calc(var(--size) / 2)'"
            ></bazis-icon>
            {{ option.$snapshot.name || t(option.$snapshot.nameKey, option.$snapshot.nameParams) }}
        </span>
    </div>
</ng-container>
